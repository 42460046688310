import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Choose Meeting Types" : "Choose Meeting Types",
      "Location" : "Location",
      "Choose Date & Time" : "Choose Date & Time",
      "Fill in your details" : "Fill in your details",
      "Go Back" : "Go Back",
      "Confirm" : "Confirm",
      "Submit" : "Submit",
      "Sunday" : "Sunday",
      "Monday" : "Monday",
      "Tuesday" : "Tuesday",
      "Wednesday" : "Wednesday",
      "Thursday" : "Thursday",
      "Friday" : "Friday",
      "Saturday" : "Saturday",
      "January" : "January",
      "February" : "February",
      "March" : "March",
      "April" : "April",
      "May" : "May",
      "June" : "June",
      "July" : "July",
      "August" : "August",
      "September" : "September",
      "October" : "October",
      "November" : "November",
      "December" : "December",
      "Su" : "Su",
      "Mo" : "Mo",
      "Tu" : "Tu",
      "We" : "We",
      "Th" : "Th",
      "Fr" : "Fr",
      "Sa" : "Sa",
      "Yes" : "Yes",
      "No" : "No",
      "Confirmation" : "Confirmation",
      "Are you sure want to reschedule the meeting" : "Are you sure want to reschedule the meeting",
      "Are you sure want to cancel the meeting" : "Are you sure want to cancel the meeting",
      "Name" : "Name",
      "Email" : "Email",
      "Phone Number" : "Phone Number",
      "Confirmed" : "Confirmed",
      "You are scheduled with" : "You are scheduled with",
      "A calendar invitation has been sent to your email address" : "A calendar invitation has been sent to your email address",
      "Get your own booking page" : "Get your own booking page",
      "Powered by" : "Powered by",
      "Cancel Meeting" : "Cancel Meeting",
      "Previous schedule you selected" : "Previous schedule you selected",
      "min" : "min",
      "mins" : "mins",
      "hour" : "hour",
      "hours" : "hours",
      "Loading" : "Loading",
      "Sorry, there are no available time slots for this date" : "Sorry, there are no available time slots for this date",
      "Next" : "Next",
      "Payment Information" : "Payment Information",
      "Price" : "Price",
      "Payment Terms" : "Payment Terms",
      "Card Number" : "Card Number",
      "Card Expiry" : "Card Expiry",
      "CVC" : "CVC",
      "Reschedule" : "Reschedule",
      "Cancel" : "Cancel",
      "Meeting" : "Meeting",
      "Type your reasons here" : "Type your reasons here",
      "Canceled" : "Canceled",
      "The meeting has been cancelled" : "The meeting has been cancelled",
      "Back to Schedule" : "Back to Schedule",
      "Error" : "Error",
      "occured" : "occured",
      "Contact Us" : "Contact Us",
      "The page you are looking for could not be found" : "The page you are looking for could not be found",
      "Invalid" : "Invalid",
      "Add a message": "Add a message",
      "Description": "Description",
      "Date & Time": "Date & Time",
      "Previous Schedule": "Previous Schedule",
      "New Schedule": "New Schedule",
      "Show Less": "Show Less",
      "Show More": "Show More",
      "See More": "See More",
      "Search Timezone": "Search Timezone",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "Phone Number is required",
      "Location is required": "Location is required",
      "Show Preview" : "Show Preview",
      "Details will be sent via confirmation email": "Details will be sent via confirmation email"
    }
  },
  id: {
    translation: {
      "Choose Meeting Types" : "Pilih Jenis Pertemuan",
      "Location" : "Lokasi",
      "Choose Date & Time" : "Pilih Hari dan Waktu",
      "Fill in your details" : "Isi data diri Anda",
      "Go Back" : "Kembali",
      "Confirm" : "Konfirmasi",
      "Submit" : "Kirim",
      "Sunday" : "Minggu",
      "Monday" : "Senin",
      "Tuesday" : "Selasa",
      "Wednesday" : "Rabu",
      "Thursday" : "Kamis",
      "Friday" : "Jumat",
      "Saturday" : "Sabtu",
      "January" : "Januari",
      "February" : "Februari",
      "March" : "Maret",
      "April" : "April",
      "May" : "Mei",
      "June" : "Juni",
      "July" : "Juli",
      "August" : "Agustus",
      "September" : "September",
      "October" : "Oktober",
      "November" : "November",
      "December" : "Desember",
      "Su" : "Mi",
      "Mo" : "Se",
      "Tu" : "Sel",
      "We" : "Ra",
      "Th" : "Ka",
      "Fr" : "Ju",
      "Sa" : "Sa",
      "Yes" : "Ya",
      "No" : "Tidak",
      "Confirmation" : "Konfirmasi",
      "Are you sure want to reschedule the meeting" : "Apakah Anda yakin untuk menjadwalkan ulang pertemuan",
      "Are you sure want to cancel the meeting" : "Apakah Anda yakin untuk membatalkan pertemuan",
      "Name" : "Nama",
      "Email" : "Email",
      "Phone Number" : "Nomor Telepon",
      "Confirmed" : "Terkonfirmasi",
      "You are scheduled with" : "Anda dijadwalkan dengan",
      "A calendar invitation has been sent to your email address" : "Undangan kalender telah dikirim ke alamat email Anda",
      "Get your own booking page" : "Dapatkan halaman pemesanan Anda sendiri",
      "Powered by" : "Dipersembahkan oleh",
      "Cancel Meeting" : "Batalkan Pertemuan",
      "Previous schedule you selected" : "Jadwal sebelumnya yang Anda pilih",
      "min" : "menit",
      "mins" : "menit",
      "hour" : "jam",
      "hours" : "jam",
      "Loading" : "Mohon menunggu",
      "Sorry, there are no available time slots for this date" : "Maaf, tidak ada slot waktu yang tersedia untuk tanggal ini",
      "Next" : "Lanjut",
      "Payment Information" : "Informasi Pembayaran",
      "Price" : "Harga",
      "Payment Terms" : "Syarat Pembayaran",
      "Card Number" : "Nomor Kartu",
      "Card Expiry" : "Kadaluwarsa Kartu",
      "CVC" : "Kartu Verifikasi Kode",
      "Reschedule" : "Jadwalkan Ulang",
      "Cancel" : "Batalkan",
      "Meeting" : "Pertemuan",
      "Type your reasons here" : "Tulis alasanmu di sini",
      "Canceled" : "Dibatalkan",
      "The meeting has been cancelled" : "Pertemuan telah dibatalkan",
      "Back to Schedule" : "Kembali ke halaman penjadwalan",
      "Error" : "Kesalahan",
      "occured" : "terjadi",
      "Contact Us" : "Kontak Kami",
      "The page you are looking for could not be found" : "Halaman yang Anda cari tidak ditemukan",
      "Invalid" : "Tidak valid",
      "Add a message" : "Tambahkan pesan",
      "Description": "Deskripsi",
      "Date & Time": "Hari dan Jam",
      "Previous Schedule": "Jadwal Sebelumnya",
      "New Schedule": "Jadwal baru",
      "Show Less": "Sembunyikan",
      "Show More": "Tampilkan",
      "See More": "Lihat",
      "Search Timezone": "Cari Zona Waktu",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "Masukkan data nomor telepon",
      "Location is required": "Masukkan data lokasi",
      "Show Preview" : "Tampilkan Pratinjau",
      "Details will be sent via confirmation email": "Detail akan dikirimkan ke email Anda"
    }
  },
  de: { // Germany
    translation: {
      "Choose Meeting Types" : "Meeting-Typen auswählen",
      "Location" : "Standort",
      "Choose Date & Time" : "Wählen Sie Datum und Uhrzeit",
      "Fill in your details" : "Geben Sie Ihre Daten ein",
      "Go Back" : "Geh zurück",
      "Confirm" : "Auswahl bestätigen",
      "Submit" : "Absenden",
      "Sunday" : "Sonntag",
      "Monday" : "Montag",
      "Tuesday" : "Dienstag",
      "Wednesday" : "Mittwoch",
      "Thursday" : "Donnerstag",
      "Friday" : "Freitag",
      "Saturday" : "Samstag",
      "January" : "Januar",
      "February" : "Februar",
      "March" : "März",
      "April" : "April",
      "May" : "Mai",
      "June" : "Juni",
      "July" : "Juli",
      "August" : "August",
      "September" : "September",
      "October" : "Oktober",
      "November" : "November",
      "December" : "Dezember",
      "Su" : "So",
      "Mo" : "Mo",
      "Tu" : "Di",
      "We" : "Mi",
      "Th" : "Do",
      "Fr" : "Fr",
      "Sa" : "Sa",
      "Yes" : "Jawohl",
      "No" : "Nein",
      "Confirmation" : "Bestätigung",
      "Are you sure want to reschedule the meeting" : "Möchten Sie das Meeting wirklich verschieben",
      "Are you sure want to cancel the meeting" : "Möchten Sie das Meeting wirklich abbrechen",
      "Name" : "Name",
      "Email" : "Email",
      "Phone Number" : "Telefonnummer",
      "Confirmed" : "Bestätigt",
      "You are scheduled with" : "Ihr Termin mit",
      "A calendar invitation has been sent to your email address" : "Eine Kalendereinladung wurde an Ihre E-Mail-Adresse gesendet",
      "Get your own booking page" : "Holen Sie sich Ihre eigene Buchungsseite",
      "Powered by" : "Unterstützt von",
      "Cancel Meeting" : "Termin absagen",
      "Previous schedule you selected" : "Bisheriger Termin, den Sie ausgewählt haben",
      "min" : "Min.",
      "mins" : "Min.",
      "hour" : "Std.",
      "hours" : "Std.",
      "Loading" : "Wird geladen",
      "Sorry, there are no available time slots for this date" : "Für dieses Datum sind leider keine Zeitfenster verfügbar",
      "Next" : "Weiter",
      "Payment Information" : "Zahlungsinformationen",
      "Price" : "Preis",
      "Payment Terms" : "Zahlungsbedingungen",
      "Card Number" : "Kartennummer",
      "Card Expiry" : "Kartenablauf",
      "CVC" : "Kartenprüfnummer",
      "Reschedule" : "Termin verschieben",
      "Cancel" : "Abbrechen",
      "Meeting" : "Treffen",
      "Type your reasons here" : "Geben Sie hier Ihre Gründe ein",
      "Canceled" : "Abgesagt",
      "The meeting has been cancelled" : "Das Treffen wurde abgesagt",
      "Back to Schedule" : "Weiteren Termin planen",
      "Error" : "Fehler",
      "occured" : "aufgetreten",
      "Contact Us" : "Kontaktiere uns",
      "The page you are looking for could not be found" : "Die gesuchte Seite konnte nicht gefunden werden",
      "Invalid" : "Ungültig",
      "Add a message": "Nachricht hinzufügen",
      "Description": "Beschreibung",
      "Date & Time": "Termin & Uhrzeit",
      "Previous Schedule": "Vorheriger Zeitplan",
      "New Schedule": "Neue Terminplanung",
      "Show Less": "Zeige weniger",
      "Show More": "Zeig mehr",
      "See More": "Mehr sehen",
      "Search Timezone": "Zeitzone suchen",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "Telefonnummer ist erforderlich",
      "Location is required": "Ort ist erforderlich",
      "Show Preview" : "Vorschau zeigen",
      "Details will be sent via confirmation email": "Details werden per Bestätigungs-E-Mail gesendet"
    }
  },
  es: { // spanyol
    translation: {
      "Choose Meeting Types" : "Elija tipos de reunión",
      "Location" : "Localización",
      "Choose Date & Time" : "Elija fecha y hora",
      "Fill in your details" : "Complete sus datos",
      "Go Back" : "Regresa",
      "Confirm" : "Confirmar",
      "Submit" : "Enviar",
      "Sunday" : "Domingo",
      "Monday" : "Lunes",
      "Tuesday" : "Martes",
      "Wednesday" : "Miércoles",
      "Thursday" : "Jueves",
      "Friday" : "Viernes",
      "Saturday" : "Sábado",
      "January" : "Enero",
      "February" : "Febrero",
      "March" : "Marzo",
      "April" : "Abril",
      "May" : "Mayo",
      "June" : "Junio",
      "July" : "Julio",
      "August" : "Agosto",
      "September" : "Septiembre",
      "October" : "Octubre",
      "November" : "Noviembre",
      "December" : "Diciembre",
      "Su" : "Do",
      "Mo" : "Lu",
      "Tu" : "Ma",
      "We" : "Mi",
      "Th" : "Ju",
      "Fr" : "Vi",
      "Sa" : "Sa",
      "Yes" : "sí",
      "No" : "No",
      "Confirmation" : "Confirmación",
      "Are you sure want to reschedule the meeting" : "¿Estás segura de que quieres reprogramar la reunión",
      "Are you sure want to cancel the meeting" : "¿Seguro que quieres cancelar la reunión",
      "Name" : "Nombre",
      "Email" : "Correo electrónico",
      "Phone Number" : "Número de teléfono",
      "Confirmed" : "Confirmada",
      "You are scheduled with" : "Estás programado con",
      "A calendar invitation has been sent to your email address" : "Se ha enviado una invitación de calendario a su dirección de correo electrónico.",
      "Get your own booking page" : "Obtenga su propia página de reserva",
      "Powered by" : "Energizado por",
      "Cancel Meeting" : "Cancelar reunión",
      "Previous schedule you selected" : "Programa anterior que seleccionaste",
      "min" : "min",
      "mins" : "min",
      "hour" : "h",
      "hours" : "h",
      "Loading" : "Cargando",
      "Sorry, there are no available time slots for this date" : "Lo sentimos, no hay franjas horarias disponibles para esta fecha.",
      "Next" : "Siguiente",
      "Payment Information" : "Información del pago",
      "Price" : "Precio",
      "Payment Terms" : "Términos de pago",
      "Card Number" : "Número de tarjeta",
      "Card Expiry" : "Caducidad de la tarjeta",
      "CVC" : "CSV",
      "Reschedule" : "Reprogramar",
      "Cancel" : "Cancelar",
      "Meeting" : "Cita",
      "Type your reasons here" : "Escriba sus razones aquí",
      "Canceled" : "Cancelada",
      "The meeting has been cancelled" : "La reunión ha sido cancelada",
      "Back to Schedule" : "Volver a la programación",
      "Error" : "Error",
      "occured" : "ocurrida",
      "Contact Us" : "Contacta con nosotras",
      "The page you are looking for could not be found" : "No se pudo encontrar la página que está buscando",
      "Invalid" : "Inválida",
      "Add a message": "Añade un mensaje",
      "Description": "Descripción",
      "Date & Time": "Fecha y hora",
      "Previous Schedule": "Programa anterior",
      "New Schedule": "Nuevo horario",
      "Show Less": "Muestra menos",
      "Show More": "Mostrar más",
      "See More": "Ver más",
      "Search Timezone": "Zona horaria de búsqueda",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "Se requiere número de teléfono",
      "Location is required": "Se requiere ubicación",
      "Show Preview" : "Mostrar vista previa",
      "Details will be sent via confirmation email": "Los detalles se enviarán por correo electrónico de confirmación."
    }
  },
  pt: { // portugese
    translation: {
      "Choose Meeting Types" : "Escolha os tipos de reunião",
      "Location" : "Localização",
      "Choose Date & Time" : "Escolha a data e hora",
      "Fill in your details" : "Preencha seus dados",
      "Go Back" : "Volte",
      "Confirm" : "confirme",
      "Submit" : "Enviar",
      "Sunday" : "Domingo",
      "Monday" : "Segunda-feira",
      "Tuesday" : "Terça",
      "Wednesday" : "Quarta-feira",
      "Thursday" : "Quinta-feira",
      "Friday" : "Sexta-feira",
      "Saturday" : "Sábado",
      "January" : "Janeiro",
      "February" : "fevereiro",
      "March" : "marzo",
      "April" : "abril",
      "May" : "Poderia",
      "June" : "Junho",
      "July" : "Julho",
      "August" : "agosto",
      "September" : "setembro",
      "October" : "Outubro",
      "November" : "novembro",
      "December" : "dezembro",
      "Su" : "dom",
      "Mo" : "seg",
      "Tu" : "ter",
      "We" : "qua",
      "Th" : "qui",
      "Fr" : "sex",
      "Sa" : "sab",
      "Yes" : "Sim",
      "No" : "Não",
      "Confirmation" : "Confirmação",
      "Are you sure want to reschedule the meeting" : "Tem certeza que deseja reagendar a reunião",
      "Are you sure want to cancel the meeting" : "Tem certeza que deseja cancelar a reunião",
      "Name" : "Nome",
      "Email" : "O email",
      "Phone Number" : "Número de telefone",
      "Confirmed" : "Confirmada",
      "You are scheduled with" : "Você está programado com",
      "A calendar invitation has been sent to your email address" : "Um convite de calendário foi enviado para o seu endereço de e-mail",
      "Get your own booking page" : "Obtenha sua própria página de reservas",
      "Powered by" : "Distribuído por",
      "Cancel Meeting" : "Cancelar Reunião",
      "Previous schedule you selected" : "Programação anterior que você selecionou",
      "min" : "min",
      "mins" : "mins",
      "hour" : "h",
      "hours" : "hrs",
      "Loading" : "Carregando",
      "Sorry, there are no available time slots for this date" : "Desculpe, não há horários disponíveis para esta data",
      "Next" : "Siguiente",
      "Payment Information" : "Informação de pagamento",
      "Price" : "Preço",
      "Payment Terms" : "Termos de pagamento",
      "Card Number" : "Número do cartão",
      "Card Expiry" : "Vencimento do cartão",
      "CVC" : "código de confirmação",
      "Reschedule" : "reagendar",
      "Cancel" : "Cancelar",
      "Meeting" : "Reunião",
      "Type your reasons here" : "Digite seus motivos aqui",
      "Canceled" : "Cancelada",
      "The meeting has been cancelled" : "A reunião foi cancelada",
      "Back to Schedule" : "Voltar à programação",
      "Error" : "Erro",
      "occured" : "ocorreu",
      "Contact Us" : "Contate-Nos",
      "The page you are looking for could not be found" : "A página que você está procurando não foi encontrada",
      "Invalid" : "Inválida",
      "Add a message": "Adiciona uma mensagem",
      "Description": "Descrição",
      "Date & Time": "Data hora",
      "Previous Schedule": "Cronograma Anterior",
      "New Schedule": "Novo cronograma",
      "Show Less": "Mostre menos",
      "Show More": "Mostre mais",
      "See More": "Ver mais",
      "Search Timezone": "Pesquisar fuso horário",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "O número de telefone é obrigatório",
      "Location is required": "A localização é obrigatória",
      "Show Preview" : "Mostrar visualização",
      "Details will be sent via confirmation email": "Os detalhes serão enviados por e-mail de confirmação"
    }
  },
  "zh-CN": { // chinese traditional
    translation: {
      "Choose Meeting Types" : "選擇會議類型",
      "Location" : "地點",
      "Choose Date & Time" : "選擇日期和時間",
      "Fill in your details" : "填寫您的詳細信息",
      "Go Back" : "回去",
      "Confirm" : "確認",
      "Submit" : "提交",
      "Sunday" : "星期日",
      "Monday" : "星期一",
      "Tuesday" : "星期二",
      "Wednesday" : "星期二",
      "Thursday" : "星期四",
      "Friday" : "星期五",
      "Saturday" : "星期六",
      "January" : "一月",
      "February" : "二月",
      "March" : "三月",
      "April" : "四月",
      "May" : "五月",
      "June" : "六月",
      "July" : "七月",
      "August" : "八月",
      "September" : "九月",
      "October" : "十月",
      "November" : "十一月",
      "December" : "十二月",
      "Su" : "週日",
      "Mo" : "週一",
      "Tu" : "週二",
      "We" : "週三",
      "Th" : "週四",
      "Fr" : "週五",
      "Sa" : "週六",
      "Yes" : "是的",
      "No" : "不",
      "Confirmation" : "確認",
      "Are you sure want to reschedule the meeting" : "您確定要重新安排會議嗎",
      "Are you sure want to cancel the meeting" : "您確定要取消會議嗎",
      "Name" : "姓名",
      "Email" : "電子郵件",
      "Phone Number" : "電話號碼",
      "Confirmed" : "確認的",
      "You are scheduled with" : "你被安排與",
      "A calendar invitation has been sent to your email address" : "日曆邀請已發送至您的電子郵件地址",
      "Get your own booking page" : "獲取您自己的預訂頁面",
      "Powered by" : "供電",
      "Cancel Meeting" : "取消會議",
      "Previous schedule you selected" : "您選擇的上一個時間表",
      "min" : "分鐘",
      "mins" : "分鐘",
      "hour" : "小時",
      "hours" : "小時",
      "Loading" : "加載中",
      "Sorry, there are no available time slots for this date" : "抱歉，此日期沒有可用的時間段",
      "Next" : "下一步",
      "Payment Information" : "支付信息",
      "Price" : "價錢",
      "Payment Terms" : "付款條件",
      "Card Number" : "卡號",
      "Card Expiry" : "卡到期",
      "CVC" : "氯乙烯",
      "Reschedule" : "改期",
      "Cancel" : "撤消",
      "Meeting" : "會議",
      "Type your reasons here" : "在此處輸入您的原因",
      "Canceled" : "取消",
      "The meeting has been cancelled" : "會議已取消",
      "Back to Schedule" : "返回日程",
      "Error" : "錯誤",
      "occured" : "發生了",
      "Contact Us" : "聯繫我們",
      "The page you are looking for could not be found" : "找不到您要找的頁面",
      "Invalid" : "無效的",
      "Add a message": "添加消息",
      "Description": "描述",
      "Date & Time": "約會時間",
      "Previous Schedule": "上一個時間表",
      "New Schedule": "新時間表",
      "Show Less": "顯示較少",
      "Show More": "展示更多",
      "See More": "查看更多",
      "Search Timezone": "搜索時區",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "電話號碼為必填項",
      "Location is required": "位置為必填項",
      "Show Preview" : "顯示預覽",
      "Details will be sent via confirmation email": "詳細信息將通過確認電子郵件發送"
    }
  },
  fr: { // france
    translation: {
      "Choose Meeting Types" : "Choisissez les types de réunion",
      "Location" : "Emplacement",
      "Choose Date & Time" : "Choisissez la date et l'heure",
      "Fill in your details" : "Remplissez vos coordonnées",
      "Go Back" : "Retourner",
      "Confirm" : "Vérifier",
      "Submit" : "Soumettre",
      "Sunday" : "Dimanche",
      "Monday" : "Lundi",
      "Tuesday" : "Mardi",
      "Wednesday" : "Mercredi",
      "Thursday" : "Jeudi",
      "Friday" : "Vendredi",
      "Saturday" : "Samedi",
      "January" : "Janvier",
      "February" : "Février",
      "March" : "Mars",
      "April" : "Avril",
      "May" : "Mai",
      "June" : "Juin",
      "July" : "Juillet",
      "August" : "Août",
      "September" : "Septembre",
      "October" : "Octobre",
      "November" : "Novembre",
      "December" : "Décembre",
      "Su" : "Dim",
      "Mo" : "Lun",
      "Tu" : "Mar",
      "We" : "Mer",
      "Th" : "Jeu",
      "Fr" : "Ven",
      "Sa" : "Sam",
      "Yes" : "Oui",
      "No" : "Non",
      "Confirmation" : "Confirmation",
      "Are you sure want to reschedule the meeting" : "Voulez-vous vraiment reprogrammer la réunion",
      "Are you sure want to cancel the meeting" : "Voulez-vous vraiment annuler la réunion",
      "Name" : "Nom",
      "Email" : "E-mail",
      "Phone Number" : "Numéro de téléphone",
      "Confirmed" : "Confirmé",
      "You are scheduled with" : "Vous êtes programmé avec",
      "A calendar invitation has been sent to your email address" : "Une invitation de calendrier a été envoyée à votre adresse e-mail",
      "Get your own booking page" : "Obtenez votre propre page de réservation",
      "Powered by" : "Alimenté par",
      "Cancel Meeting" : "Annuler la réunion",
      "Previous schedule you selected" : "Programme précédent que vous avez sélectionné",
      "min" : "min",
      "mins" : "mins",
      "hour" : "heure",
      "hours" : "heures",
      "Loading" : "Le bourrage",
      "Sorry, there are no available time slots for this date" : "Désolé, il n'y a pas de créneaux disponibles pour cette date",
      "Next" : "Valider",
      "Payment Information" : "Informations de paiement",
      "Price" : "Prix",
      "Payment Terms" : "Modalités de paiement",
      "Card Number" : "Numéro de carte",
      "Card Expiry" : "Expiration de la carte",
      "CVC" : "CVC",
      "Reschedule" : "Reporter",
      "Cancel" : "Annuler",
      "Meeting" : "Réunion",
      "Type your reasons here" : "Tapez vos raisons ici",
      "Canceled" : "Annulé",
      "The meeting has been cancelled" : "La réunion a été annulée",
      "Back to Schedule" : "Retour à l'horaire",
      "Error" : "Erreur",
      "occured" : "s'est produit",
      "Contact Us" : "Nous contacter",
      "The page you are looking for could not be found" : "La page que vous recherchez est introuvable",
      "Invalid" : "Invalide",
      "Add a message": "Ajouter un message",
      "Description": "La description",
      "Date & Time": "Date et heure",
      "Previous Schedule": "Calendrier précédent",
      "New Schedule": "Nouveau programme",
      "Show Less": "Montrer moins",
      "Show More": "Montre plus",
      "See More": "Voir plus",
      "Search Timezone": "Fuseau horaire de recherche",
      "Guest Email(s)": "Guest Email(s)",
      "Phone Number is required" : "Le numéro de téléphone est requis",
      "Location is required": "L'emplacement est requis",
      "Show Preview" : "Afficher l'aperçu",
      "Details will be sent via confirmation email": "Les détails seront envoyés par e-mail de confirmation"
    }
  },
  it: { //italia
    translation: {
      "Choose Meeting Types" : "Scegli i tipi di riunione",
      "Location" : "la localizzazione",
      "Choose Date & Time" : "Scegli Data e ora",
      "Fill in your details" : "Inserisci i tuoi dati",
      "Go Back" : "Tornare",
      "Confirm" : "Confermare",
      "Submit" : "Invia",
      "Sunday" : "Domenica",
      "Monday" : "Lunedi",
      "Tuesday" : "Martedì",
      "Wednesday" : "Mercoledì",
      "Thursday" : "Giovedì",
      "Friday" : "Venerdì",
      "Saturday" : "Sabato",
      "January" : "Gennaio",
      "February" : "Febbraio",
      "March" : "Marzo",
      "April" : "Aprile",
      "May" : "Maggio",
      "June" : "Giugno",
      "July" : "Luglio",
      "August" : "Agosto",
      "September" : "Settembre",
      "October" : "Ottobre",
      "November" : "Novembre",
      "December" : "Dicembre",
      "Su" : "Do",
      "Mo" : "Lu",
      "Tu" : "Ma",
      "We" : "Me",
      "Th" : "Gi",
      "Fr" : "Ve",
      "Sa" : "Sa",
      "Yes" : "Sì",
      "No" : "No",
      "Confirmation" : "Conferma",
      "Are you sure want to reschedule the meeting" : "Sei sicuro di voler riprogrammare la riunione",
      "Are you sure want to cancel the meeting" : "Sei sicuro di voler annullare la riunione",
      "Name" : "Nome",
      "Email" : "E-mail",
      "Phone Number" : "Numero di telefono",
      "Confirmed" : "Inveterato",
      "You are scheduled with" : "Sei programmato con",
      "A calendar invitation has been sent to your email address" : "Un invito del calendario è stato inviato al tuo indirizzo email",
      "Get your own booking page" : "Ottieni la tua pagina di prenotazione",
      "Powered by" : "Offerto da",
      "Cancel Meeting" : "Annulla riunione",
      "Previous schedule you selected" : "Orario precedente selezionato",
      "min" : "minuto",
      "mins" : "minuti",
      "hour" : "ora",
      "hours" : "ore",
      "Loading" : "Caricamento in corso",
      "Sorry, there are no available time slots for this date" : "Spiacenti, non ci sono fasce orarie disponibili per questa data",
      "Next" : "Prossimo",
      "Payment Information" : "Informazioni sul pagamento",
      "Price" : "Prezzo",
      "Payment Terms" : "Termini di pagamento",
      "Card Number" : "Numero di carta",
      "Card Expiry" : "Scadenza della carta",
      "CVC" : "CVC",
      "Reschedule" : "Riprogrammare",
      "Cancel" : "Annulla",
      "Meeting" : "Incontro",
      "Type your reasons here" : "Digita qui le tue ragioni",
      "Canceled" : "Annullato",
      "The meeting has been cancelled" : "La riunione è stata annullata",
      "Back to Schedule" : "Torna al programma",
      "Error" : "Errore",
      "occured" : "avvenuto",
      "Contact Us" : "Contattaci",
      "The page you are looking for could not be found" : "Impossibile trovare la pagina che stai cercando",
      "Invalid" : "Non valido",
      "Add a message": "Aggiungi un messaggio",
      "Description": "Descrizione",
      "Date & Time": "Appuntamento",
      "Previous Schedule": "Orario precedente",
      "New Schedule": "Nuovo programma",
      "Show Less": "Mostra meno",
      "Show More": "Mostra di più",
      "See More": "Vedi altro",
      "Search Timezone": "Cerca Fuso orario",
      "Details will be sent via confirmation email": "I dettagli verranno inviati tramite e-mail di conferma"
    }
  },
  da: { // denmark / danish / dansk
    translation: {
      "Choose Meeting Types" : "Vælg Mødetyper",
      "Location" : "Beliggenhed",
      "Choose Date & Time" : "Vælg dato og tid",
      "Fill in your details" : "Udfyld dine oplysninger",
      "Go Back" : "Gå tilbage",
      "Confirm" : "Bekræfte",
      "Submit" : "Indsend",
      "Sunday" : "Søndag",
      "Monday" : "Mandag",
      "Tuesday" : "Tirsdag",
      "Wednesday" : "Onsdag",
      "Thursday" : "Torsdag",
      "Friday" : "Fredag",
      "Saturday" : "Lørdag",
      "January" : "Januar",
      "February" : "Februar",
      "March" : "Marts",
      "April" : "April",
      "May" : "Maj",
      "June" : "Juni",
      "July" : "Juli",
      "August" : "August",
      "September" : "September",
      "October" : "Oktober",
      "November" : "November",
      "December" : "December",
      "Su" : "Sø",
      "Mo" : "Ma",
      "Tu" : "Ti",
      "We" : "On",
      "Th" : "To",
      "Fr" : "Fr",
      "Sa" : "Lø",
      "Yes" : "Ja",
      "No" : "Nej",
      "Confirmation" : "Bekræftelse",
      "Are you sure want to reschedule the meeting" : "Er du sikker på at du vil flytte mødet",
      "Are you sure want to cancel the meeting" : "Er du sikker på at du vil aflyse mødet",
      "Name" : "Navn",
      "Email" : "E-mail",
      "Phone Number" : "Telefonnummer",
      "Confirmed" : "Bekræftet",
      "You are scheduled with" : "Du har booket aftalen",
      "A calendar invitation has been sent to your email address" : "En kalenderinvitation er blevet sendt til din e-mailadresse",
      "Get your own booking page" : "Få din egen bookingside",
      "Powered by" : "Drevet af",
      "Cancel Meeting" : "Aflys møde",
      "Previous schedule you selected" : "Tidligere tidsplan du valgte",
      "min" : "minut",
      "mins" : "minutter",
      "hour" : "time",
      "hours" : "timer",
      "Loading" : "Indlæser",
      "Sorry, there are no available time slots for this date" : "Beklager, der er ingen ledige tidsrum for denne dato",
      "Next" : "Næste",
      "Payment Information" : "Betalingsinformation",
      "Price" : "Pris",
      "Payment Terms" : "Betalingsbetingelser",
      "Card Number" : "Kortnummer",
      "Card Expiry" : "Kortets udløb",
      "CVC" : "CVC",
      "Reschedule" : "Flyt tidsplanen",
      "Cancel" : "Afbestille",
      "Meeting" : "Møde",
      "Type your reasons here" : "Skriv dine grunde her",
      "Canceled" : "Aflyst",
      "The meeting has been cancelled" : "Mødet er blevet aflyst",
      "Back to Schedule" : "Tilbage til kalenderen",
      "Error" : "Fejl",
      "occured" : "opstod",
      "Contact Us" : "Kontakt os",
      "The page you are looking for could not be found" : "Den side, du leder efter, blev ikke fundet",
      "Invalid" : "Ugyldig",
      "Add a message": "Tilføj en besked",
      "Description": "Beskrivelse",
      "Date & Time": "Dato tid",
      "Previous Schedule": "Tidligere aftale",
      "New Schedule": "Nyt skema",
      "Show Less": "Vis mindre",
      "Show More": "Vis mere",
      "See More": "Se mere",
      "Search Timezone": "Søg tidszone",
      "Details will be sent via confirmation email": "Detaljer vil blive sendt via bekræftelsesmail"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
